import * as React from "react";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <main className="grid place-items-center h-screen text-white">
      <div className="space-y-4">
        <title>Not found</title>
        <h1 className="text-3xl">404 Page not found</h1>
        <p className="text-xl">
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </div>
    </main>
  );
};

export default NotFoundPage;
